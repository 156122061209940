// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-1-charlevere-index-js": () => import("/vercel/path0/src/pages/1-charlevere/index.js" /* webpackChunkName: "component---src-pages-1-charlevere-index-js" */),
  "component---src-pages-2-animalkind-js": () => import("/vercel/path0/src/pages/2-animalkind.js" /* webpackChunkName: "component---src-pages-2-animalkind-js" */),
  "component---src-pages-2-lalande-index-js": () => import("/vercel/path0/src/pages/2-lalande/index.js" /* webpackChunkName: "component---src-pages-2-lalande-index-js" */),
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

